import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading'; import PageTitle from 'Dashboard/components/PageTitle';
;
const GoldenfpQuestion = (props) => {
    useEffect(() => {
        let loginRole = localStorage.getItem('role');
        if (loginRole === '1' || loginRole === '2') {
            window.location.href = '/dashboard/message_center'
        } else if (loginRole === '3') {
            window.location.href = '/dashboard/pet'
        } else if (loginRole == '4') {
            window.location.href = '/dashboard/challenges'
        }
    }, []);

    let [isLoading, setIsLoading] = useState(true),
        [dataQuestion, setDataQuestion] = useState([]),
        [totalCheckInNum, setTotalCheckInNum] = useState({ day1: 0, day2: 0 })

    let handleAPI = {
        FCList: function () {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/FCList`
            let postData = { GetFC: "goldenbrokerFC05040505" }
            axios.post(API, postData)
                .then((res) => {
                    console.log(res)
                    let { ResponseCode } = res.data
                    if (ResponseCode == '0') {
                        setIsLoading(false)
                        setDataQuestion(res.data.FCQList)
                    } else {
                        setIsLoading(false)
                        alert(`取得資料錯誤（${ResponseCode}）`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    alert('網路連線錯誤!請聯繫管理人員')
                })
        }
    }
    useEffect(() => { handleAPI.FCList() }, [])

    const handleEvent = {
        filterQuestionNum: function (data) {
            let day1Num = []
            let day2Num = []
            data.map((item) => {
                if (item.CU == 'FC2404181234') {
                    day1Num.push(item)
                }
                if (item.CU == 'FC2404195678') {
                    day2Num.push(item)
                }
            })

            setTotalCheckInNum({ day1: day1Num.length, day2: day2Num.length })
        }
    }

    useEffect(() => {
        if (dataQuestion.length > 0) {
            handleEvent.filterQuestionNum(dataQuestion)
        }
    }, [dataQuestion])
    useEffect(() => {
    }, [totalCheckInNum]);

    return (
        <>
            <Loading isLoading={isLoading} />
            <PageTitle data={props} />
            <div className='ms-3'>
                <span className='fw-bolder'>總提問數：</span>
                <span className='bg-dark-blue text-light p-2 mx-1 rounded'>Day1：{totalCheckInNum.day1} 項</span>
                <span className='bg-dark-blue text-light p-2 mx-1 rounded'>Day2：{totalCheckInNum.day2} 項</span>
            </div>
            <div className='container'>
                <div className='px-2'>
                    <ul className="nav nav-tabs w-100 bg-light pt-3" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pending-tab" data-bs-toggle="tab" data-bs-target="#pending" type="button" role="tab" aria-controls="pending" aria-selected="true">Day1</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="checkOK-tab" data-bs-toggle="tab" data-bs-target="#checkOK" type="button" role="tab" aria-controls="checkOK" aria-selected="false">Day2</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        {/* 5/4 */}
                        <div className="tab-pane fade show active" id="pending" role="tabpanel" aria-labelledby="pending-tab">
                            <div className="container">
                                <table className="table table-striped table-hover mt-2">
                                    <thead className="text-golden-brown align-items">
                                        <tr>
                                            <th width='150px'>提問人</th>
                                            <th width='230px'>時間</th>
                                            <th className='text-start'>課程</th>
                                            <th className='text-start'>提問</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataQuestion.filter((val) => {
                                                if (val.CU == 'FC2404181234') {
                                                    return val
                                                }
                                            }).map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.Name}</td>
                                                        <td>{item.DT}</td>
                                                        <td>{item?.Q?.split('_')[0]}</td>
                                                        <td>{item?.Q?.split('_')[1]}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* 5/5 */}
                        <div className="tab-pane fade" id="checkOK" role="tabpanel" aria-labelledby="checkOK-tab">
                            <div className="col-12">
                                <div className="container">
                                    <table className="table table-striped table-hover mt-2">
                                        <thead className="text-golden-brown align-items">
                                            <tr>
                                                <th width='150px'>提問人</th>
                                                <th width='150px'>時間</th>
                                                <th className='text-start'>課程</th>
                                                <th className='text-start'>提問</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataQuestion.filter((val) => {
                                                    if (val.CU == 'FC2404195678') {
                                                        return val
                                                    }
                                                }).map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.Name}</td>
                                                            <td>{item.DT}</td>
                                                            <td>{item?.Q?.split('_')[0]}</td>
                                                            <td>{item?.Q?.split('_')[1]}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default GoldenfpQuestion