import React, { useEffect, useState } from "react";
import axios from "axios";

//components
import Loading from '../components/Loading';
import LineLogin from '../assets/javascript/lineLogin'

import DownloadFile from "../assets/javascript/downloadFile";

const ProductFooter = (props) => {
    let [isLoading, setIsLoading] = useState(false)
    let [textLoading, setTextLoading] = useState(false)

    //GET DOWNLOAD API
    let [downloadData, setDownloadData] = useState([]);
    useEffect(() => {
        LineLogin('/products/main1'); //LINE 登入狀態驗證
        let downloadAPI = `${process.env.REACT_APP_GOLDEN_API5000}GcsList/title=3`;
        // axios.default.withCredentials = true;
        axios.get(downloadAPI, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/products/main1');
                        window.location.href = '/login'
                        return
                    } else {
                        setDownloadData(res.data)
                        setIsLoading(false)
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                alert('請重新整理')
            })
    }, []);
    //GET DOWNLOAD API END

    //資料處理
    let filterData = [];
    const splitData = (data) => {
        let splitAry = [];
        data.forEach((item, index) => {
            splitAry.push(item.split('/'));
        })
        splitAry.forEach((item, index) => {
            filterData.push({
                title: item[0],
                option: item[1],
                fileName: item[2],
                splitFileName: `${item[2].split('_')[0]}_${item[2].split('_')[1]}`,
                splitOption: item[1].split('-')[1],
                downloadFile: item.join('/')
            })
        })
    }
    splitData(downloadData)
    //資料處理 END

    //篩選
    let filterOption = [];
    const filterOptionFun = (data) => {
        data.forEach((item, index) => {
            if (props.title === item.splitFileName) {
                filterOption.push(item)
            }
        })
    }
    filterOptionFun(filterData)
    //篩選 END

    const handleDownloadFile = (e) => {
        setTextLoading(true)
        e.preventDefault();
        let { dataset } = e.target;
        let API = `${process.env.REACT_APP_GOLDEN_API5000}GcsList` //API
        let pageName = '/products/main1' //跳轉 PAGE 名稱
        DownloadFile(API, dataset.filename, dataset.download, pageName, setTextLoading)
    }
    return (
        <>
            <Loading isLoading={isLoading} />
            <Loading isLoading={textLoading} circleHide={true}>
                <h5 className={`text-center bg-golden-brown p-2 rounded goto-page pe-5 fw-bolder`}
                    data-text="•••">檔案讀取中</h5>
            </Loading>
            <div className="d-table" style={{ height: '68px' }}></div>
            <div className="bg-light-brown product-footer py-2">
                <div className="container">
                    <h6 className={`text-dark-blue fw-bolder text-center ${props.title === '請先選擇商品' ? '' : 'mb-2'}`}>{props.title}</h6>
                    <ul className={`list-unstyled d-flex flex-wrap justify-content-center ${props.title === '請先選擇商品' ? 'd-none' : 'd-block'}`}>
                        {
                            filterOption.map((item, index) => {
                                return (
                                    <li className="footer-item">
                                        <a href="#" data-download={item.downloadFile} data-fileName={item.fileName} className="text-dark-blue fw-bolder" key={item.splitOption} onClick={handleDownloadFile}>{item.splitOption}</a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ProductFooter;