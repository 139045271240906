import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading';
import PageTitle from 'Dashboard/components/PageTitle';
const GoldenfpList = (props) => {
    useEffect(() => {
        let loginRole = localStorage.getItem('role');
        if (loginRole === '1' || loginRole === '2') {
            window.location.href = '/dashboard/message_center'
        } else if (loginRole === '3') {
            window.location.href = '/dashboard/pet'
        } else if (loginRole == '4') {
            window.location.href = '/dashboard/challenges'
        }
    }, []);

    let [isLoading, setIsLoading] = useState(true),
        [dataList, setDataList] = useState([]),
        [dataQuestion, setDataQuestion] = useState([]),
        [searchInput, setSearchInput] = useState(''),
        [totalCheckInNum, setTotalCheckInNum] = useState({ day1: 0, day2: 0 })

    let handleAPI = {
        FCList: function () {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}iSmart/FCList`
            let postData = { GetFC: "goldenbrokerFC05040505" }
            axios.post(API, postData)
                .then((res) => {
                    console.log(res)
                    let { ResponseCode } = res.data
                    if (ResponseCode == '0') {
                        setIsLoading(false)
                        setDataList(res.data.FCList)
                        setDataQuestion(res.data.FCQList)
                    } else {
                        setIsLoading(false)
                        alert(`取得資料錯誤（${ResponseCode}）`)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    alert('網路連線錯誤!請聯繫管理人員')
                })
        }
    }
    useEffect(() => { handleAPI.FCList() }, [])

    const handleEvent = {
        changeSearchInput: function (e) {
            setSearchInput(e.target.value.replace(/\s+/g, ""))
            searchData = []
        },
        filterCheckInNum: function (data) {
            let day1Num = []
            let day2Num = []
            data.map((item) => {
                if (item.Day1 !== '') {
                    day1Num.push(item)
                }
                if (item.Day2 !== '') {
                    day2Num.push(item)
                }
            })

            setTotalCheckInNum({ day1: day1Num.length, day2: day2Num.length })
        }
    }

    //搜尋
    let searchData = dataList.filter((item, index) => {
        if (searchInput == '') {
            return item
        } else if (item.Name.includes(searchInput) || item.Id.includes(searchInput) || item.QMobile.includes(searchInput)) {
            return item
        }
    })
    useEffect(() => {
        if (dataList.length > 0) {
            handleEvent.filterCheckInNum(dataList)
        }
    }, [dataList, dataQuestion])
    useEffect(() => { }, [searchInput]);
    useEffect(() => { }, [totalCheckInNum]);

    return (
        <>
            <Loading isLoading={isLoading} />
            <PageTitle data={props} />
            <div className='ms-5'>
                <span className='fw-bolder'>已簽到人數：</span>
                <span className='bg-dark-blue text-light p-2 mx-1 rounded'>Day1：{totalCheckInNum.day1} 位</span>
                <span className='bg-dark-blue text-light p-2 mx-1 rounded'>Day2：{totalCheckInNum.day2} 位</span>
            </div>
            <div className="px-5 pt-3 position-relative">
                <div className="col-12 col-md-8 col-lg-5 mx-auto">
                    <input type="text" className="form-control" placeholder="搜尋輸入(提供姓名、電話、身份ID 查詢)" onChange={handleEvent.changeSearchInput} />
                </div>
                <div className="table-responsive-xxl text-center mt-3" style={{ height: '80vh', overflowY: 'scroll' }}>
                    <table className="table table-striped table-hover">
                        <thead className="text-golden-brown align-items">
                            <tr>
                                <th width='150px'>姓名</th>
                                <th width='180px'>手機</th>
                                <th width='200px' className='text-start'>簽到時間</th>
                                <th width='150px'>財顧推薦人</th>
                                <th className='text-start'>參加原因</th>
                                <th className='text-start' width='500px'>提問</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchData.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.Name}</td>
                                            <td>{item.QMobile}</td>
                                            <td className='text-start'>
                                                <p className='my-1'><span className='fw-bolder'>Day1：</span><br/>{item.Day1 == '' ? <span className='text-danger'>尚未簽到</span> : item.Day1}</p>
                                                <p className='my-1'><span className='fw-bolder'>Day2：</span><br/>{item.Day2 == '' ? <span className='text-danger'>尚未簽到</span> : item.Day2}</p>
                                            </td>
                                            <td>{item.QRefName}</td>
                                            <td className='text-start'>{item.QRsn}</td>
                                            <td className='text-start'>
                                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={`panelsStayOpen${index}-headingOne`}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen${index}-collapseOne`} aria-expanded="false" aria-controls={`panelsStayOpen${index}-collapseOne`}>
                                                                Day1
                                                            </button>
                                                        </h2>
                                                        <div id={`panelsStayOpen${index}-collapseOne`} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen${index}-headingOne`}>
                                                            <div className="accordion-body">
                                                                {
                                                                    dataQuestion.filter((kval) => {
                                                                        console.log(kval)
                                                                        if (kval.LineId == item.LineId && kval.CU == 'FC2404181234') {
                                                                            return kval
                                                                        }
                                                                    }).map((kitem, kindex) => {
                                                                        return (<p>{`Q${kindex + 1}：${kitem['Q']}`}</p>)

                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={`panelsStayOpen${index}-headingTwo`}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen${index}-collapseTwo`} aria-expanded="false" aria-controls={`panelsStayOpen${index}-collapseTwo`}>
                                                                Day2
                                                            </button>
                                                        </h2>
                                                        <div id={`panelsStayOpen${index}-collapseTwo`} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen${index}-headingTwo`}>
                                                            <div className="accordion-body">
                                                                {
                                                                    dataQuestion.filter((kval) => {
                                                                        console.log(kval)
                                                                        if (kval.LineId == item.LineId && kval.CU == 'FC2404195678') {
                                                                            return kval
                                                                        }
                                                                    }).map((kitem, kindex) => {
                                                                        return (<p>{`Q${kindex + 1}：${kitem['Q']}`}</p>)

                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export default GoldenfpList