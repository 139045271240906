import React, { useState, useEffect } from 'react';
import Loading from 'components/Loading';
import moment from 'moment';
import axios from 'axios';
import ExcelJs from 'exceljs';
import PageTitle from 'Dashboard/components/PageTitle';

const PlayerList = (props) => {
    let [isLoading, setIsLoading] = useState(true)
    let [allData, setAllData] = useState([]) //全部噴回來的資料 儲存
    let [newData, setNewData] = useState(null)
    let [resData, setResData] = useState(null)
    let [chooseYear, setChooseYear] = useState(moment().format('YYYY'))
    let [chooseType, setChooseType] = useState('golf') //golf 高爾夫 ; soft 軟性活動
    const handleEvent = {
        getData: function (yearVal) { //取得所有 data
            setIsLoading(true)
            let domain = process.env.REACT_APP_GOLDEN_NPS_API
            axios.all([
                axios.get(`${domain}get_golf_player_all/${yearVal}`),
                axios.get(`${domain}get_golf_audience_all/${yearVal}`)
            ])
                .then(axios.spread((golfData, softData) => {
                    if (golfData.data.code === 0) {
                        setIsLoading(false)
                        setResData({
                            golf: golfData.data.data,
                            soft: softData.data.data
                        })
                    } else {
                        alert(`抓取資料錯誤(${golfData.data.code})`)
                    }
                }))
                .catch((err) => {
                    console.log(err)
                    alert('網路連線錯誤，請通知管理員(err)')
                })
        },
        //download excel
        downloadFile: function (e) {
            e.preventDefault();
            const workbook = new ExcelJs.Workbook();
            downloadData.forEach((sheetData) => {
                const sheet = workbook.addWorksheet(sheetData.sheetName);
                sheet.addTable({
                    name: sheetData.sheetName,
                    ref: `A1`, // 從A1開始
                    headerRow: true,
                    columns: sheetData.thead.map((s) => {
                        return { name: s };
                    }),
                    rows: sheetData.tbody
                });
                if (sheetData.columnWidths) {
                    sheetData.columnWidths.forEach((column) => {
                        sheet.getColumn(column.number).width = column.width;
                    });
                }
            });

            // 表格裡面的資料都填寫完成之後，訂出下載的callback function
            // 異步的等待他處理完之後，創建url與連結，觸發下載
            workbook.xlsx.writeBuffer().then((content) => {
                const link = document.createElement('a');
                const blob = new Blob([content], {
                    type: 'application/vnd.ms-excel;charset=utf-8;'
                });
                link.download = `${moment().format('YYYY-MM-DD')}_高爾夫球&軟性活動名單.xlsx`;
                link.href = URL.createObjectURL(blob);
                link.click();
            });
        },
        getYearList: function () {
            let min = '2024',
                thisYear = moment().format('YYYY')

            let array = []
            for (let i = Number(min); i <= Number(thisYear); i++) {
                array.push(i)
            }
            return array
        },
        chooseYear: function (val) {
            if (val !== chooseYear) {
                setChooseYear(val)
                handleEvent.getData(val)
                setNewData([])
            }
        }
    }

    useEffect(() => {
        if (resData !== null) {
            let newGolfData = resData.golf.map((item) => {
                return {
                    GO_NAME: item.GO_NAME,
                    GO_GENDER: item.GO_GENDER == '0' ? '男' : '女',
                    GO_BIRTHDAY: item.GO_BIRTHDAY,
                    GO_MOBILE: item.GO_MOBILE,
                    GO_EMAIL: item.GO_EMAIL,
                    GO_COMPANY: item.GO_COMPANY,
                    GO_JBTITLE: item.GO_JBTITLE,
                    GO_TYPE: item.GO_TYPE == '0' ? '一般' : '公益',
                    GO_DIET: item.GO_DIET == '0' ? '葷' : '素',
                    GO_POLO_TYPE: item.GO_POLO_TYPE == '0' ? '男' : '女',
                    GO_POLO_SIZE: item.GO_POLO_SIZE,
                    GO_DINNER: item.GO_DINNER == '0' ? '要' : '不要',
                    GO_BILL: item.GO_BILL == '0' ? '要' : '不要',
                    GO_INVHEADER: item.GO_INVHEADER,
                    GO_TAXNUM: item.GO_TAXNUM,
                    GO_PARTNER: item.GO_PARTNER,
                    GO_HANDICAP: item.GO_HANDICAP
                }
            })
            let newSoftData = resData.soft.map((item) => {
                return {
                    GO_YEAR: item.GO_YEAR,
                    GO_NAME: item.GO_NAME,
                    GO_GENDER: item.GO_GENDER == '0' ? '男' : '女',
                    GO_BIRTHDAY: item.GO_BIRTHDAY,
                    GO_MOBILE: item.GO_MOBILE,
                    GO_EMAIL: item.GO_EMAIL,
                    GO_COMPANY: item.GO_COMPANY,
                    GO_JBTITLE: item.GO_JBTITLE,
                    GO_DIET: item.GO_DIET == '0' ? '葷' : '素',
                    GO_DINNER: item.GO_DINNER == '0' ? '要' : '不要',
                    GO_BILL: item.GO_BILL == '0' ? '要' : '不要',
                    GO_INVHEADER: item.GO_INVHEADER,
                    GO_TAXNUM: item.GO_TAXNUM
                }
            })
            setNewData({ golf: newGolfData, soft: newSoftData })
        }
    }, [resData])

    let downloadData = newData &&
        [
            {
                sheetName: '高爾夫球名單',
                thead: ['姓名', '性別', '出生年月日', '手機號碼', '電子信箱', '任職單位', '職稱', '報名組別', '飲食需求', 'POLO版', 'POLO尺寸', '是否晚宴', '是否發票', '發票抬頭', '統一編號', '同組貴賓', '差點'],
                tbody: newData.golf.map((item) => {
                    return [
                        item.GO_NAME,
                        item.GO_GENDER,
                        item.GO_BIRTHDAY,
                        item.GO_MOBILE,
                        item.GO_EMAIL,
                        item.GO_COMPANY,
                        item.GO_JBTITLE,
                        item.GO_TYPE,
                        item.GO_DIET,
                        item.GO_POLO_TYPE,
                        item.GO_POLO_SIZE,
                        item.GO_DINNER,
                        item.GO_BILL,
                        item.GO_INVHEADER,
                        item.GO_TAXNUM,
                        item.GO_PARTNER,
                        item.GO_HANDICAP
                    ]
                }),
                columnWidths: [
                    { number: 1, width: 10 },
                    { number: 2, width: 10 },
                    { number: 3, width: 15 },
                    { number: 4, width: 20 },
                    { number: 5, width: 30 },
                    { number: 6, width: 20 },
                    { number: 7, width: 20 },
                    { number: 8, width: 10 },
                    { number: 9, width: 10 },
                    { number: 10, width: 10 },
                    { number: 11, width: 10 },
                    { number: 12, width: 10 },
                    { number: 13, width: 10 },
                    { number: 14, width: 20 },
                    { number: 15, width: 20 },
                    { number: 16, width: 20 },
                    { number: 17, width: 10 }
                ]
            },
            {
                sheetName: '軟性活動名單',
                thead: ['年度', '姓名', '性別', '出生年月日', '手機號碼', '電子信箱', '任職單位', '職稱', '飲食需求', '是否晚宴', '是否發票', '發票抬頭', '統一編號'],
                tbody: newData.soft.map((item) => {
                    return [
                        item.GO_YEAR,
                        item.GO_NAME,
                        item.GO_GENDER,
                        item.GO_BIRTHDAY,
                        item.GO_MOBILE,
                        item.GO_EMAIL,
                        item.GO_COMPANY,
                        item.GO_JBTITLE,
                        item.GO_DIET,
                        item.GO_DINNER,
                        item.GO_BILL,
                        item.GO_INVHEADER,
                        item.GO_TAXNUM
                    ]
                }),
                columnWidths: [
                    { number: 1, width: 10 },
                    { number: 2, width: 10 },
                    { number: 3, width: 10 },
                    { number: 4, width: 20 },
                    { number: 5, width: 20 },
                    { number: 6, width: 25 },
                    { number: 7, width: 20 },
                    { number: 8, width: 20 },
                    { number: 9, width: 10 },
                    { number: 10, width: 10 },
                    { number: 11, width: 10 },
                    { number: 12, width: 20 },
                    { number: 13, width: 20 }
                ]
            }
        ]

    useEffect(() => {
        handleEvent.getData(chooseYear);
    }, [])

    return (
        <>
            <Loading isLoading={isLoading} />
            <PageTitle data={props} />
            {
                newData !== null &&
                <div className='h-100'>
                    <div className='container p-4 challenge position-relative'>
                        <div className='row align-items-center mb-3'>
                            <div className='col-6'>
                                <div className='d-flex align-items-center'>
                                    <span className='fw-bolder'>選擇年度：</span>
                                    <select className='form-select w-25' onClick={e => handleEvent.chooseYear(e.target.value)}>
                                        {handleEvent.getYearList().map((item) => {
                                            return <option value={item} key={item} selected={chooseYear == item}>{item} 年</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='text-end'><button className='btn bg-dark-blue text-light' onClick={handleEvent.downloadFile} style={{ height: '42px' }}>下載 Excel</button></div>
                            </div>
                        </div>
                        <ul className='nav nav-tabs w-100 bg-light mb-3' id='myTab' role='tablist'>
                            <li className='nav-item' role='presentation'>
                                <button className={`nav-link fw-bolder ${chooseType === 'golf' && 'active'}`} type='button' value='golf' onClick={e => setChooseType(e.target.value)}>高爾夫球</button>
                            </li>
                            <li className='nav-item' role='presentation'>
                                <button className={`nav-link fw-bolder ${chooseType === 'soft' && 'active'}`} type='button' value='soft' onClick={e => setChooseType(e.target.value)}>軟性活動</button>
                            </li>
                        </ul>
                        <div>
                            <p className='fw-bolder text-dark-blue'>共：{newData[chooseType].length} 位</p>
                            <div style={{ height: '80vh', overflowY: 'scroll' }}>
                                <table className='table table-striped table-light align-middle'>
                                    <thead>
                                        <tr style={{ fontSize: '12pt', background: '#c0c0c0' }}>
                                            {
                                                chooseType === 'golf' ?
                                                    <>
                                                        <th width='100px'>姓名</th>
                                                        <th width='100px'>手機號碼<br />電子信箱<br />任職單位(職稱)</th>
                                                        <th width='100px'>報名組別 </th>
                                                        <th width='100px'>飲食需求 </th>
                                                        <th width='100px'>POLO版/尺寸</th>
                                                        <th width='100px'>晚宴</th>
                                                        <th width='100px'>發票<br />發票抬頭<br />統一編號</th>
                                                        <th width='100px'>同組貴賓 </th>
                                                        <th width='100px'>差點</th>
                                                    </>
                                                    :
                                                    <>
                                                        <th width='100px'>年度</th>
                                                        <th width='100px'>姓名</th>
                                                        <th width='100px'>手機號碼<br />電子信箱<br />任職單位(職稱)</th>
                                                        <th width='100px'>報名組別 </th>
                                                        <th width='100px'>飲食需求 </th>
                                                        <th width='100px'>晚宴</th>
                                                        <th width='100px'>發票<br />發票抬頭<br />統一編號</th>
                                                    </>
                                            }
                                        </tr>
                                    </thead>
                                    {
                                        chooseType === 'golf' &&
                                        <tbody>
                                            {
                                                newData.golf.map((item) => {
                                                    return (
                                                        <tr key={`player-${item.GO_MOBILE}`}>
                                                            <td className='fw-bolder'>{item.GO_NAME}({item.GO_GENDER})</td>
                                                            <td className='fw-bolder'>{item.GO_MOBILE}<br />{item.GO_EMAIL}<br />{item.GO_COMPANY}({item.GO_JBTITLE})</td>
                                                            <td className='fw-bolder'>{item.GO_TYPE}</td>
                                                            <td className='fw-bolder'>{item.GO_DIET}</td>
                                                            <td className='fw-bolder'>{item.GO_POLO_TYPE}/{item.GO_POLO_SIZE}</td>
                                                            <td className='fw-bolder'>{item.GO_DINNER}</td>
                                                            <td className='fw-bolder'>{item.GO_BILL}<br />{item.GO_INVHEADER}<br />{item.GO_TAXNUM}</td>
                                                            <td className='fw-bolder'>{item.GO_PARTNER}</td>
                                                            <td className='fw-bolder'>{item.GO_HANDICAP}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {
                                                newData.golf.length == 0 && <tr><td colSpan={17} className='fw-bolder text-center'>無資料</td></tr>
                                            }
                                        </tbody>
                                    }
                                    {
                                        chooseType === 'soft' &&
                                        <tbody>
                                            {
                                                newData.soft.map((item) => {
                                                    return (
                                                        <tr key={`player-${item.GO_MOBILE}`}>
                                                            <td className='fw-bolder'>{item.GO_YEAR}</td>
                                                            <td className='fw-bolder'>{item.GO_NAME}({item.GO_GENDER})</td>
                                                            <td className='fw-bolder'>{item.GO_MOBILE}<br />{item.GO_EMAIL}<br />{item.GO_COMPANY}({item.GO_JBTITLE})</td>
                                                            <td className='fw-bolder'>{item.GO_TYPE}</td>
                                                            <td className='fw-bolder'>{item.GO_DIET}</td>
                                                            <td className='fw-bolder'>{item.GO_DINNER}</td>
                                                            <td className='fw-bolder'>{item.GO_BILL}<br />{item.GO_INVHEADER}<br />{item.GO_TAXNUM}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {
                                                newData.soft.length == 0 && <tr><td colSpan={17} className='fw-bolder text-center'>無資料</td></tr>
                                            }
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default PlayerList