import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { CloseButton, Modal } from 'react-bootstrap';
//components
import Loading from 'components/Loading';

//from
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { getRole } from 'Dashboard/authorization/Authorization';
import PageTitle from 'Dashboard/components/PageTitle';

const Permissions = (props) => {

    let roleList = [
        { title: '最高', role: ['0'] },
        { title: '發訊', role: ['1', '2'] },
        { title: '五大挑戰', role: ['4', '6'] },
        { title: '財顧', role: ['5'] },
        { title: '業務服務', role: ['7'] },
        { title: '推薦商品', role: ['8'] }
    ]

    //登入角色判斷
    useEffect(() => {
        let loginRole = localStorage.getItem('role');
        if (loginRole === '1' || loginRole === '2') {
            window.location.href = '/dashboard/message_center'
        } else if (loginRole === '3') {
            window.location.href = '/dashboard/pet'
        }
    }, []);

    let [isLoading, setLoading] = useState(true);
    let [isTabTarget, setIsTabTarget] = useState('最高')

    //API
    //---GET API
    let [getMember, setGetMember] = useState([])
    const getAllAccFun = () => {
        let getUserAPI = `${process.env.REACT_APP_GOLDEN_BACK_API}Account/GetAllAcc`;
        axios.get(getUserAPI)
            .then((res) => {
                setGetMember(res.data);
                setLoading(false)
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }
    useEffect(() => {
        getAllAccFun();
    }, []);

    let [show, setShow] = useState(false);
    const handleFunModalClose = (e) => {
        setShow(false)
    }

    let [data, setData] = useState({
        User_Name: '',
        User_Login: '',
        User_Role: '',
        User_OldPassword: '',
        User_Password: ''
    })

    //--ADD MEMBER
    //表單驗證
    const validationSchema = Yup.object().shape({
        User_Name: Yup.string()
            .required('使用者姓名不能為空白'),
        // .email('Email is invalid'),
        User_Login: Yup.string()
            .required('使用者信箱不能為空白'),
        // .email('Email is invalid'),
        User_Password: Yup.string()
            // .min(6, 'Password must be at least 6 characters')
            .required('使用者密碼不能為空白'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    let [addBtnLoading, setAddBtnLoading] = useState(false)
    const [addShow, setAddShow] = useState(false);

    const handleClose = () => {
        reset();
        setAddShow(false)
    }
    const handleShow = () => setAddShow(true);

    const onSubmit = (data) => {
        let API = `${process.env.REACT_APP_GOLDEN_BACK_API}Account/Add`
        data.Password_Salt = ''
        setAddBtnLoading(true)
        axios.post(API, data)
            .then((res) => {
                if (res.data.ResponseMsg === 'Success') {
                    reset()
                    setAddBtnLoading(false)
                    setAddShow(false)
                    getAllAccFun();
                }
            })
            .catch((err) => {

                alert('請重新整理')
            })
    }
    //--- ADD END

    const handleRoleChange = (e) => {
        setData({ ...data, User_Role: Number(e.target.value) })
    }

    let [modalFun, setModalFun] = useState(null);

    useEffect(() => {
    }, [data, modalFun]);

    const handleFunction = (e) => {
        e.preventDefault();
        let { dataset } = e.target;
        getMember.forEach((item, index) => {
            if (dataset.id === item.User_Name) {
                data.User_Name = item.User_Name
                data.User_Login = item.User_Login
                data.User_Role = Number(item.User_Role)
            }
        })
        setModalFun(dataset.fun)

        if (dataset.fun === 'role') {
            setShow(true)
            delete data.User_OldPassword
            delete data.User_Password
        } else if (dataset.fun === 'password') {
            setShow(true)
            delete data.User_Role
        } else if (dataset.fun === 'delete') {
            let delUserAPI = `${process.env.REACT_APP_GOLDEN_BACK_API}Account/DeleteAcc`;
            axios.delete(delUserAPI, {
                data: data
            })
                .then((res) => {
                    if (res.data.ResponseCode === '0') {
                        alert('刪除成功')
                        getAllAccFun();
                    }
                })
                .catch((err) => {

                    alert('請重新整理')
                })
        }
    }
    //--- PUT ROLE
    let [btnLoading, setBtnLoading] = useState(false);
    const handleChangeRole = (e) => {
        e.preventDefault();
        setBtnLoading(true)

        if (modalFun === 'role') {
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}Account/UpdateRole`;
            axios.put(API, data)
                .then((res) => {
                    if (res.data.ResponseCode === '0') {
                        alert('角色更改成功 ~~')
                        setBtnLoading(false)
                        setShow(false)
                        getAllAccFun();
                    } else {
                        alert('更改角色失敗 >< 請重新更改')
                    }
                })
                .catch((err) => {

                    alert('請重新整理')
                })
        } else if (modalFun === 'password') {
            if (data.User_Password === '') {
                alert('新密碼不能為空白!')
                setBtnLoading(false)
                return
            }
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}Account/UpdatePwd`;
            axios.put(API, data)
                .then((res) => {
                    if (res.data.ResponseCode === '0') {
                        alert('修改密碼成功 ~~ ')
                        setBtnLoading(false)
                        setShow(false)
                        getAllAccFun();
                    } else {
                        alert('更改密碼失敗 >< 舊密碼輸入錯誤!!')
                        setData({ ...data, User_OldPassword: '', User_Password: '' })
                        setBtnLoading(false)
                    }
                })
                .catch((err) => {

                    alert('請重新整理')
                })
        }
    }
    //API

    //密碼眼睛
    let [eye, setEye] = useState(true);
    const handleEyeClick = () => {
        if (eye) {
            setEye(false);
        } else {
            setEye(true);
        }
    }


    return (
        <>
            <Loading isLoading={isLoading} />
            <PageTitle data={props} />
            <div className="position-relative px-5">
                <button className="btn btn-dark-blue add-btn d-flex align-items-center" onClick={handleShow}><span className='ms-2 fw-bolder'>新增帳號</span></button>
                <ul className='nav nav-tabs my-3' id='myTab'>
                    {
                        roleList.map((item, index) => {
                            return (
                                <li key={`presentation-${index + 1}`}>
                                    <button className={`fw-bolder nav-link btn-golden-brown ${isTabTarget == item.title && 'active'}`} type='button' role='tab' onClick={e => setIsTabTarget(item.title)}>{item.title}管理</button>
                                </li>
                            )
                        })
                    }
                </ul>
                <div className="table-responsive-xxl text-center">
                    <table className="table table-striped table-hover">
                        <thead className="text-golden-brown">
                            <tr>
                                <th className="text-start" style={{ whiteSpace: 'nowrap' }}>姓名</th>
                                <th className="text-start" style={{ whiteSpace: 'nowrap' }}>Email</th>
                                <th className="text-start" style={{ whiteSpace: 'nowrap' }}>權限</th>
                                <th className="text-start" style={{ whiteSpace: 'nowrap' }}>功能</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getMember.map((item, index) => {
                                    if (getRole(item.User_Role.toString()).includes(isTabTarget)) {
                                        return (
                                            <tr className='fw-bolder' id={item.User_Name} key={index}>
                                                <td className="text-start">{item.User_Name}</td>
                                                <td className="text-start">{item.User_Login}</td>
                                                <td className="text-start">{getRole(item.User_Role.toString())}</td>
                                                <td className="text-start">
                                                    <button className="btn p-0 mx-1 role-btn"
                                                        data-id={item.User_Name}
                                                        data-fun="role"
                                                        onClick={handleFunction}
                                                    ></button>
                                                    <button className="btn p-0 mx-1 password-btn"
                                                        data-id={item.User_Name}
                                                        data-fun="password"
                                                        onClick={handleFunction}></button>
                                                    <button className="btn p-0 ms-5 del-btn text-danger"
                                                        data-id={item.User_Name}
                                                        data-fun="delete"
                                                        onClick={handleFunction}></button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {/* add member modal */}
                <Modal show={addShow} onHide={handleClose} animation={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>新增使用者</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pt-0">
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <div className="form-group my-3">
                                <label htmlFor="User_Name">使用者姓名</label>
                                <input id="User_Name" name="User_Name" type="text" {...register('User_Name')} className={`form-control ${errors.User_Name ? 'is-invalid' : ''}`}
                                />
                                <div className="invalid-feedback">{errors.User_Name?.message}</div>
                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="User_Login">使用者信箱</label>
                                <input id="User_Login" name="email" type="text" {...register('User_Login')} className={`form-control ${errors.User_Login ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.User_Login?.message}</div>
                            </div>
                            <div className="form-group position-relative my-3">
                                <label htmlFor="password">使用者密碼</label>
                                <input id="User_Password" name="User_Password" type={eye ? 'password' : 'text'} {...register('User_Password')} className={`form-control ${errors.User_Password ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.User_Password?.message}</div>
                                <button type="button" className="btn text-dark-blue position-absolute" style={{ right: '-3px', top: '24px' }} onClick={handleEyeClick}><FontAwesomeIcon icon={eye ? faEye : faEyeSlash} /></button>
                            </div>
                            <div className="my-3">
                                <label htmlFor="role">使用者角色</label>
                                <select className={`form-select ${errors.User_Role ? 'is-invalid' : ''}`} id='role' name='User_Role' {...register('User_Role')}>
                                    {
                                        roleList.map((item, index) => {
                                            return (
                                                <optgroup label={`${item.title}管理`} key={`role-${index + 1}`}>
                                                    {item.role.map((kitem, kindex) => {
                                                        return (
                                                            <option key={`role-item-${kindex + 1}`} value={kitem} selected={kitem == 0 && 'selected'}>{getRole(kitem.toString())}</option>
                                                        )
                                                    })}
                                                </optgroup>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="text-end">
                                <button type="button" class="btn btn-outline-golden-brown mx-1" onClick={handleClose}>關閉</button>
                                <button type="submit" className="btn btn-golden-brown mx-1" disabled={addBtnLoading ? 'disabled' : ''}>
                                    <div className={`spinner-border text-light spinner-border--width me-2 ${addBtnLoading ? 'd-inline-block' : 'd-none'}`} role="status" style={{ width: '1rem', height: '1rem' }}></div>
                                    儲存
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
                {/* edit modal */}
                <Modal show={show} onHide={handleFunModalClose} animation={true}>
                    <Modal.Header>
                        <Modal.Title>{modalFun === 'role' ? '變更使用者角色' : '變更使用者密碼'}</Modal.Title>
                        <CloseButton aria-label="Close" onClick={handleFunModalClose} />
                    </Modal.Header>
                    <Modal.Body className={`pt-0 ${modalFun === 'role' ? 'd-block' : 'd-none'}`}>
                        <form>
                            <div className="form-group my-3">
                                <label htmlFor="User_Name">使用者姓名</label>
                                <input id="User_Name" name="User_Name" type="text" className={`form-control`} disabled="disabled" value={data.User_Name} />

                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="User_Login">使用者信箱</label>
                                <input id="User_Login" name="email" type="email" className={`form-control `} disabled="disabled" value={data.User_Login} />

                            </div>
                            <div className="my-3">
                                <label htmlFor="role">使用者角色</label>
                                <select className="form-select" id="role" name="User_Role" onChange={handleRoleChange}
                                    value={data.User_Role}>
                                    {
                                        roleList.map((item, index) => {
                                            return (
                                                <optgroup label={`${item.title}管理`} key={`role-${index + 1}`}>
                                                    {item.role.map((kitem, kindex) => {
                                                        return (
                                                            <option key={`role-item-${kindex + 1}`} value={kitem} selected={kitem == 0 && 'selected'}>{getRole(kitem.toString())}</option>
                                                        )
                                                    })}
                                                </optgroup>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="text-end">
                                <button type="button" className="btn btn-outline-golden-brown mx-1" data-btn="close" onClick={handleFunModalClose}>關閉</button>
                                <button type="button" className="btn btn-golden-brown mx-1" onClick={handleChangeRole} disabled={btnLoading ? 'btnLoading' : ''}>
                                    <div className={`spinner-border text-light spinner-border--width me-2 ${btnLoading ? 'd-inline-block' : 'd-none'}`} role="status" style={{ width: '1rem', height: '1rem' }}></div>
                                    儲存
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Body className={`pt-0 ${modalFun === 'password' ? 'd-block' : 'd-none'}`}>
                        <form>
                            <div className="form-group my-3">
                                <label htmlFor="Pas_User_Name">使用者姓名</label>
                                <input id="Pas_User_Name" name="Pas_User_Name" type="text" className={`form-control`} disabled="disabled" value={data.User_Name} />

                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="Pas_User_Login">使用者信箱</label>
                                <input id="Pas_User_Login" name="email" type="email" className={`form-control `} disabled="disabled" value={data.User_Login} />
                            </div>
                            <div className="form-group my-3 position-relative">
                                <label htmlFor="User_OldPassword">密碼(舊密碼)</label>
                                <input id="User_OldPassword" name="oldPassword" type={eye ? 'password' : 'text'} className={`form-control `}
                                    onChange={e => setData({ ...data, User_OldPassword: e.target.value })} />
                                <button type="button" className="btn text-dark-blue position-absolute" style={{ right: '-1px', top: '24px' }} onClick={handleEyeClick}><FontAwesomeIcon icon={eye ? faEye : faEyeSlash} /></button>

                            </div>
                            <div className="form-group my-3">
                                <label htmlFor="User_Password">密碼(新密碼)</label>
                                <input id="User_Password" name="Password" type={eye ? 'password' : 'text'} className={`form-control `}
                                    onChange={e => setData({ ...data, User_Password: e.target.value })} />
                            </div>

                            <div className="text-end">
                                <button type="button" className="btn btn-outline-golden-brown mx-1" data-btn="close" onClick={handleFunModalClose}>關閉</button>
                                <button type="button" className="btn btn-golden-brown mx-1" onClick={handleChangeRole} disabled={btnLoading ? 'btnLoading' : ''}>
                                    <div className={`spinner-border text-light spinner-border--width me-2 ${btnLoading ? 'd-inline-block' : 'd-none'}`} role="status" style={{ width: '1rem', height: '1rem' }}></div>
                                    儲存
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
export default Permissions