import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faHandshake,
  faMedal,
  faStream,
  faCrown,
} from "@fortawesome/free-solid-svg-icons";

const DashboardNav = (props) => {
  let [allNavItem, setAllNavItem] = useState([
    {
      title: "發訊管理",
      open: true,
      icon: faComment,
      mainRole: "0,1,2", //子頁-所包含的角色代碼
      list: [
        {
          itemName: "發訊中心",
          path: "/dashboard/message_center",
          role: "0,1,2",
        },
        {
          itemName: "訊息審核",
          path: "/dashboard/message_check",
          role: "0,2",
        },
        {
          itemName: "推播統計",
          path: "/dashboard/push_statistics",
          role: "0",
        },
      ],
    },
    {
      title: "匯客+後台資料管理",
      open: true,
      icon: faCrown,
      mainRole: "0,8", //子頁-所包含的角色代碼
      list: [
        {
          itemName: "公勝推薦商品",
          path: "/dashboard/recommend_product",
          role: "0,8",
        },
      ],
    },
    {
      title: "權限管理",
      open: true,
      icon: faStream,
      mainRole: "0",
      list: [
        { itemName: "權限管理", path: "/dashboard/permissions", role: "0" },
        { itemName: "業務員管理", path: "/dashboard/sales", role: "0" },
      ],
    },
    {
      title: "業務服務管理",
      open: true,
      icon: faStream,
      mainRole: "0,7",
      list: [
        {
          itemName: "行專 NPS 統計表",
          path: "/dashboard/nps_data",
          role: "0,7",
        },
        {
          itemName: "行專管理",
          path: "/dashboard/nps_assistant",
          role: "0,7",
        },
        {
          itemName: "尊榮預約通道管理",
          path: "/dashboard/vip_service",
          role: "0,7",
        },
      ],
    },
    {
      title: "高爾夫球管理",
      open: true,
      icon: faHandshake,
      mainRole: "0",
      list: [
        {
          itemName: "匯出名單",
          path: "/dashboard/golf_player",
          role: "0",
        }
      ],
    },
    {
      title: "五大挑戰挑戰",
      open: true,
      icon: faMedal,
      mainRole: "0,4",
      list: [
        {
          itemName: "狂人全排行",
          path: "/dashboard/challenges_rank",
          role: "0,4",
        },
        {
          itemName: "特殊活動項目",
          path: "/dashboard/challenges_event",
          role: "0,4",
        },
        {
          itemName: "五大挑戰獎牌榜",
          path: "/dashboard/challenges_award",
          role: "0,4",
        },
      ],
    },
    {
      title: "財顧管理",
      open: true,
      icon: faHandshake,
      mainRole: "0,5,6",
      list: [
        {
          itemName: "論壇簽到表",
          path: "/dashboard/goldenfp_list",
          role: "0,5,6",
        },
        {
          itemName: "論壇總提問表",
          path: "/dashboard/goldenfp_question",
          role: "0,5,6",
        },
      ],
    },
  ]);

  let [isHaveMes, setIsHaveMes] = useState(false)
  let [todoSendPost, setTodoPost] = useState(false)
  let sessionStorageData = sessionStorage.getItem('nowSendPostData')
  useEffect(() => {
    if (sessionStorageData !== null) {
      setIsHaveMes(true)
      setTodoPost(true)
    } else {
      setIsHaveMes(false)
    }

    if (isHaveMes) {
      alert('發送推播訊息中....')
      let NOWPUSHAPI = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/Push`; //立即推播
      axios.post(NOWPUSHAPI, JSON.parse(sessionStorageData))
        .then((res) => {
          if (res.data.ResponseCode === '0') {
            alert('立即推播發送成功 !!')
            sessionStorage.removeItem('nowSendPostData')
            setIsHaveMes(false)
            setTodoPost(false)
          } else {
            alert('立即推播發送失敗 ><');
            sessionStorage.removeItem('nowSendPostData')
            setIsHaveMes(false)
            setTodoPost(false)
          }
        })
        .catch((err) => {
          alert('立即推播發送失敗 ><');
          sessionStorage.removeItem('nowSendPostData')
          setIsHaveMes(false)
          setTodoPost(false)
          console.log(err)
          // alert('請重新整理')
        })
    }
  }, [isHaveMes, sessionStorageData])

  let [loginRole, setLoginRole] = useState(localStorage.getItem('role'));

  return (
    <nav className="dashboard w-100">
      <div className='mx-2 content-scroll'>
        <div>
          <ul className="me-auto mb-2 mb-lg-0 list-unstyled">
            {allNavItem.filter((val, valIndex) => {
              if (val.mainRole.includes(loginRole)) {
                return val
              }
            }).map((item, index) => { //選單列
              return (
                <li className='mb-3' key={`nev-${index + 1}`}>
                  <p className='text-light small mb-2 dot-line-bottom-light small'><FontAwesomeIcon icon={item.icon} className="me-2" />{item.title}</p>
                  <ul className='p-0'>
                    {
                      item.list.filter((kval) => {
                        if (kval.role.includes(loginRole)) {
                          return kval
                        }
                      }).map((kitem, kindex) => {
                        return (
                          <NavLink key={`NavLink-${kindex + 1}`} to={`${kitem.path}`} activeClassName='btn-primary-gradient rounded-pill text-light' className='d-block my-2 ps-3 py-2 text-light'>{kitem.itemName}</NavLink>
                        )
                      })
                    }
                  </ul>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {
        todoSendPost ?
          <div className='position-absolute bg-dark-blue text-light p-2' style={{ top: '65px', right: '0px' }}>
            <div className='position-relative'>
              <div className={`spinner-border text-light spinner-border--width me-2 ${todoSendPost ? 'd-inline-block' : 'd-none'}`} style={{ width: '1rem', height: '1rem' }}></div>
              <span>發訊中...請勿關閉網頁</span>
            </div>
          </div> : ''
      }
    </nav>
  )
}
export default DashboardNav